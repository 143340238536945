<template>
  <v-container fluid>
    <v-row>
      <v-col xs="12">
        <v-card class="mx-auto>">
          <v-list-item class="primary">
            <v-list-item-content>
              <v-list-item-title class="headline text-left white--text"
                >My Projects</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-card-text>
            <v-row justify="end">
              <v-col>
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <!-- <v-col sm="auto" class="mt-2">
                  <v-checkbox v-model="allProjects" label="All Projects?"></v-checkbox>
                </v-col>-->
            </v-row>
          </v-card-text>
          <template>
            <v-data-table
              :headers="filteredHeaders"
              :items="projectlist"
              :items-per-page="-1"
              :search="search"
              class="pt-4"
              dense
            >
              <template v-slot:item.project="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                    <v-btn
                      small
                      color="primary"
                      v-on="on"
                      icon
                      @click="editProject(item)"
                    >
                      <v-icon class="clickable" color="primary"
                        >mdi-pencil</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Edit Project</span>
                </v-tooltip>
              </template>
              <template v-slot:item.AuditStart="{item}">
                {{ item.AuditStart | dateformat }}
              </template>
              <template v-slot:item.AuditEnd="{item}">
                {{ item.AuditEnd | dateformat }}
              </template>
            </v-data-table>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- SCRIPTS -->
<script>
import {authMethods, authComputed} from '@state/helpers'
import {get, sync, commit, call} from 'vuex-pathify'
import {debounce, isEqual, filter, sortBy, range} from 'lodash'
export default {
  name: 'userprojects',
  props: {
    subEntityId: Number,
  },
  data: () => ({
    search: '',
    userProjects: [],
    panel: [0, 1],
    allProjects: false,
  }),
  created() {
    this.getUserProjects()
  },
  activated() {
    this.getUserProjects()
  },
  watch: {
    currentUser(nval, oval) {
      if (!!nval) {
        this.getUserProjects()
      }
    },
  },
  computed: {
    ...authComputed,
    projectlist() {
      let projects = this.userProjects.map((up) => {
        return {...up.Project}
      })
      projects.sort((a, b) => {
        return a.Description > b.Description ? 1 : -1
      })
      return projects
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    headers() {
      return [
        {
          text: 'Project',
          align: 'center',
          value: 'project',
          sortable: false,
          visible: true,
        },
        {
          text: 'Project Number',
          align: 'center',
          value: 'ProjectNumber',
          visible: true,
        },
        {
          text: 'Entity',
          align: 'center',
          value: 'SubEntity.Entity.FullName',
          visible: !(this.currentUser.DivisionID == 5),
        },
        {
          text: 'SubEntity',
          align: 'center',
          value: 'SubEntity.Description',
          visible: !(
            this.currentUser.DivisionID == 4 || this.currentUser.DivisionID == 5
          ),
        },
        {
          text: 'Description',
          align: 'center',
          value: 'Description',
          visible: !(this.currentUser.DivisionID == 4),
        },
        {
          text: 'Start',
          align: 'center',
          value: 'AuditStart',
          visible: true,
        },
        {
          text: 'End',
          align: 'center',
          value: 'AuditEnd',
          visible: true,
        },
      ]
    },
  },
  methods: {
    getUserProjects() {
      if (this.currentUser) {
        let url = `/UserProject/User/${this.currentUser.ID}/Division/${this.currentUser.DivisionID}`
        this.$axios.get(url).then(
          (res) => {
            this.userProjects = res.data
          },
          (error) => {
            this.handleError(error)
            this.error = error
            console.error(error)
          }
        )
      }
    },
    editProject(project) {
      this.$router.push({
        name: 'project',
        params: {
          subEntityId: project.SubEntityID,
          projectId: project.ID,
        },
      })
    },
  },
}
</script>

<!-- STYLES -->
<style lang="scss">
//@import '@design';
</style>