import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"xs":"12"}},[_c(VCard,{staticClass:"mx-auto>"},[_c(VListItem,{staticClass:"primary"},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"headline text-left white--text"},[_vm._v("My Projects")])],1)],1),_c(VCardText,[_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,[_c(VTextField,{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),[_c(VDataTable,{staticClass:"pt-4",attrs:{"headers":_vm.filteredHeaders,"items":_vm.projectlist,"items-per-page":-1,"search":_vm.search,"dense":""},scopedSlots:_vm._u([{key:"item.project",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"small":"","color":"primary","icon":""},on:{"click":function($event){return _vm.editProject(item)}}},on),[_c(VIcon,{staticClass:"clickable",attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Project")])])]}},{key:"item.AuditStart",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateformat")(item.AuditStart))+" ")]}},{key:"item.AuditEnd",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateformat")(item.AuditEnd))+" ")]}}])})]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }